<script setup>
import Card from "@/components/Tools/Card/Card.vue";
import Header from "@/components/ExternalApps/SalesChannelManagementApp/components/Header.vue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import { useApp } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useApp";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useStore } from "@/core/services/store";
import { useMigrationIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigrationIntegration";
import { watch } from "vue";

const store = useStore();
const { integrationsWithPresets, integrations, getIntegrationsWithPresets } =
  useIntegration();
const { isBusyMount, selectIntegration } = useApp();
const {
  isProductsSelected,
  isMigrationFinished,
  hasMigration,
  hasOnlyMigration
} = useAppStatus();

const {
  shopActive,
  shopImporterActive,
  show,
  channels,
  rawChannels,
  step,
  createdProject,
  error
} = useMigrationIntegration();

function onOpenError(integration) {
  if (!integration?.salesChannel?.value?.migrationError) return;

  createdProject.value = integration;
  error.value = integration.salesChannel?.value.migrationError;
  show.value = true;
  step.value = 4;
}

function getChannelInfo(integration) {
  if (
    !integration?.salesChannel?.value?.id &&
    !integration?.salesChannel?.value?.uuid
  )
    return;

  return rawChannels.value.find(
    c =>
      c.id === integration?.salesChannel?.value?.id ||
      c.uuid === integration?.salesChannel?.value?.uuid
  );
}

// Update integration when a new one is added
watch(
  () => integrations.value,
  async () => await getIntegrationsWithPresets()
);
</script>

<template>
  <div>
    <Progressbar />
    <div class="pt-3">
      <b-skeleton-wrapper v-if="isBusyMount">
        <b-skeleton height="50px" width="100%" class="mb-4"></b-skeleton>
        <b-skeleton
          v-for="n in 4"
          :key="'skeleton-integration-' + n"
          height="155px"
          width="100%"
          class="mb-4"
        ></b-skeleton>
      </b-skeleton-wrapper>
      <div v-else>
        <!--    START: HEADER    -->
        <Header />
        <!--    END: HEADER    -->
        <!--    START: CARD-LISTING    -->
        <div v-if="integrationsWithPresets.length > 0">
          <Card
            v-for="integration in integrationsWithPresets"
            :key="integration.name"
            class="mb-4 p-4 integration"
            :class="{
              'integration--error':
                integration.salesChannel?.value?.migrationError?.length > 0
            }"
          >
            <div>
              <div class="mb-2 d-flex justify-content-between">
                <div class="d-flex align-center">
                  <span class="font-weight-bold text-h5">
                    {{
                      integration?.name?.replace(
                        store.getters.selectedApp.name,
                        ""
                      )
                    }}
                  </span>

                  <div class="status ml-2">
                    <span v-if="integration.productiveMode" class="green">
                      {{ $t("salesChannelManagementApp.migration.productive") }}
                    </span>
                    <span v-else class="gray">
                      {{
                        $t("salesChannelManagementApp.migration.development")
                      }}</span
                    >
                  </div>
                </div>

                <button
                  v-b-popover.hover.top="
                    $t('salesChannelManagementApp.titles.settings.title')
                  "
                  class="btn btn-icon text-h6"
                  @click="
                    selectIntegration(
                      integration,
                      'settings',
                      'settings',
                      'wizard'
                    )
                  "
                >
                  <i class="fal fa-gear"></i>
                </button>
              </div>
              <!--    START: ACTIONS    -->
              <div class="row justify-content-between">
                <div class="col-9">
                  <div
                    v-if="
                      Object.keys(integration.featureStatus || {}).length > 0
                    "
                    :class="{
                      'mb-4':
                        !isMigrationFinished(integration) ||
                        !isProductsSelected(integration) ||
                        integration.salesChannel?.value?.isMigrated
                    }"
                  >
                    <span class="mb-1">
                      {{
                        $t(
                          "salesChannelManagementApp.migration.activeFeatures"
                        )
                      }}:
                    </span>
                    <span
                      v-for="(value, feature) in integration.featureStatus"
                      :key="feature"
                      class="status mb-1"
                    >
                      <span :class="[value ? 'green' : 'gray']">{{
                        $t("salesChannelManagementApp.titles." + feature)
                      }}</span>
                    </span>
                  </div>
                  <template
                    v-if="
                      !integration.salesChannel?.value?.migrationError?.length
                    "
                  >
                    <div
                      v-if="!isMigrationFinished(integration)"
                      class="alert alert-info bg-white mb-0"
                    >
                      <i class="fal fa-warning"></i>
                      <span class="ml-2">{{
                        $t(
                          "salesChannelManagementApp.migration.migrationMissing"
                        )
                      }}</span>
                    </div>
                    <div
                      v-else-if="!isProductsSelected(integration)"
                      class="alert alert-info bg-white mb-0"
                    >
                      <i class="fal fa-warning"></i>
                      <span class="ml-2">{{
                        $t(
                          "salesChannelManagementApp.productSelection.productSelectionMissing"
                        )
                      }}</span>
                    </div>
                    <div
                      v-if="
                        integration.salesChannel?.value?.isMigrated &&
                        !integration.wasAlreadyActivated
                      "
                      class="alert alert-info bg-white mb-0"
                    >
                      <i class="fal fa-warning"></i>
                      <div class="ml-2">
                        <span>
                          {{
                            $t(
                              "salesChannelManagementApp.migration.migratedFromSalesChannel",
                              {
                                channelName:
                                  getChannelInfo(integration)?.bezeichnung ||
                                  getChannelInfo(integration)?.name
                              }
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <div v-else class="alert alert-danger bg-white mb-0">
                    <i class="fal fa-warning"></i>
                    <span class="ml-2">{{
                      integration.salesChannel.value.migrationError
                    }}</span>
                  </div>
                </div>
                <div class="col-auto d-flex flex-column">
                  <template
                    v-if="
                      !integration.salesChannel?.value?.migrationError?.length
                    "
                  >
                    <!-- START: Initial data import -->
                    <button
                      v-if="
                        store.getters.appConfig?.hasOnlyMigration ||
                        (hasMigration && integration.initialDataImportStatus)
                      "
                      :disabled="
                        isMigrationFinished(integration) && !hasOnlyMigration
                      "
                      class="btn btn-outline-primary mb-2"
                      @click="
                        selectIntegration(
                          integration,
                          'migration',
                          'migration',
                          'settings'
                        )
                      "
                    >
                      <span class="px-1">{{
                        $t("salesChannelManagementApp.titles.migration.title")
                      }}</span>
                    </button>
                    <!-- END: Initial data import -->
                    <template v-else>
                      <!-- START: Product selection -->
                      <button
                        v-if="
                          !store.getters?.areaBlacklist?.includes(
                            'productSelection'
                          ) ?? true
                        "
                        :disabled="!isMigrationFinished(integration)"
                        class="btn btn-outline-primary mb-2"
                        @click="
                          selectIntegration(
                            integration,
                            'productSelection',
                            'productSelection',
                            'selection'
                          )
                        "
                      >
                        <span class="px-1">{{
                          $t(
                            "salesChannelManagementApp.titles.productSelection.selection"
                          )
                        }}</span>
                      </button>
                      <!-- END: Product selection -->

                      <!-- START: Workflows -->
                      <button
                        v-if="
                          !store.getters?.areaBlacklist?.includes(
                            'workflows'
                          ) ?? true
                        "
                        :disabled="
                          !isMigrationFinished(integration) ||
                          !isProductsSelected(integration)
                        "
                        class="btn btn-outline-primary mb-2"
                        @click="selectIntegration(integration, 'workflows')"
                      >
                        <span class="px-1">{{
                          $t("salesChannelManagementApp.workflows")
                        }}</span>
                      </button>
                      <!-- END: Workflows -->

                      <!-- START: Journal -->
                      <button
                        class="btn btn-outline-primary"
                        :disabled="!isProductsSelected(integration)"
                        @click="
                          $router.push({
                            name: 'salesChannelManagementAppJournal'
                          });
                          selectIntegration(
                            integration,
                            'journal',
                            'journal',
                            '',
                            true
                          );
                        "
                      >
                        <span class="px-1">{{
                          $t("salesChannelManagementApp.journal.journal")
                        }}</span>
                      </button>
                      <!-- END: Journal -->
                    </template>
                  </template>
                  <button
                    v-else
                    class="btn btn-outline-primary mb-2"
                    @click="onOpenError(integration)"
                  >
                    <span class="px-1">{{
                      $t("salesChannelManagementApp.migration.moreInformation")
                    }}</span>
                  </button>
                </div>
              </div>
              <!--    END: ACTIONS    -->
            </div>
          </Card>
        </div>
        <div v-else>
          <div class="d-flex justify-content-center w-100">
            <Card class="pa-6">
              <div>
                <h3>
                  {{
                    $t(
                      "salesChannelManagementApp.migration.createFirstIntegrationTitle"
                    )
                  }}
                </h3>
              </div>
              <div class="mb-4">
                {{
                  $t(
                    "salesChannelManagementApp.migration.createFirstIntegrationDescription"
                  )
                }}
              </div>
              <div class="d-flex justify-content-between align-items-end">
                <div class="info-card-container">
                  <div class="info-card d-flex p-4">
                    <div class="mr-2">
                      <i class="fal fa-circle-info" />
                    </div>
                    <div v-html="$t('salesChannelManagementApp.help')"></div>
                  </div>
                </div>
                <div>
                  <button
                    v-if="
                      (shopActive || shopImporterActive) && channels.length > 0
                    "
                    class="btn btn-primary mr-4"
                    @click="show = true"
                  >
                    <i class="fal fa-diagram-next" />
                    {{
                      $t(
                        "salesChannelManagementApp.migration.migrateExistingIntegration"
                      )
                    }}
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="
                      $router.push({ name: 'salesChannelManagementAppWizard' })
                    "
                  >
                    <i class="fal fa-plus" />
                    {{ $t("salesChannelManagementApp.addIntegration") }}
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <!--    END: CARD-LISTING    -->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
i {
  color: $color-connect-text;
  transition: color 0.15s;
}

.btn-icon {
  &:hover {
    i {
      color: $color-connect-primary;
    }
  }
}

.status {
  span {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 3px;
    margin-right: 6px;

    &.green {
      background-color: #d3f8df !important;
      color: #084c2e !important;
    }

    &.gray {
      color: #162a41;
      background-color: #eaecf5;
    }

    &.primary {
      color: $color-connect-primary;
      background-color: $color-connect-primary-light !important;
    }
  }
}

.info-card-container {
  .info-card {
    background-color: #eaecf0;
    border-radius: 6px;
  }
}

.integration {
  &--error {
    border: 1px solid $color-connect-error;
  }
}
</style>
