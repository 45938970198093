import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router/composables";
import { useStore } from "@/core/services/store";
import {
  loadForms,
  loadProjects,
  loadSelectedIntegration,
  resetValuesToSave,
  setValuesToSave
} from "@/composables/useSalesChannelManagementApp";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";
import { UPDATE_SELECTED_PROJECT } from "@/core/services/store/switchArea.module";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import { productSelectionVariables } from "@/components/ExternalApps/SalesChannelManagementApp/utility/productSelectionVariables";
import {
  LOAD_CONFIG_VALUES,
  SET_VARIABLES
} from "@/core/services/store/variables_v1.module";
import { useAssignmentTables } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAssignmentTables";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import Presets from "@/components/Settings/Presets/presets";
import _ from "lodash";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";
import { useMigrationIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigrationIntegration";
import DataOptions from "@/components/Tools/FormHelper/dataOptions";
import { RESET_ROUTE_PARAMS } from "@/core/services/store/route.module";

const store = useStore();

export const useApp = () => {
  // Router
  const router = useRouter();

  const { loadChannelsForShop } = useMigrationIntegration();

  // Reactivity
  const initialized = ref(false);
  const { assignmentTables } = useAssignmentTables();
  const isBusy = computed(() => store.getters["loadingQueue/showLoadingBar"]());
  const isBusyMount = computed(() =>
    store.getters["loadingQueue/showLoadingBar"]("mountApp", "mountApp")
  );
  const { toggleStatus, isAppInitialized } = useAppStatus();
  const { getWorkflowPendingStatus, migrationStepFinished } = useMigration();

  // App Features
  const { features, selectedFeature } = useFeatures();
  const { selectedArea } = useArea();
  const { integrations, selectedIntegration, getIntegrationsWithPresets } =
    useIntegration();
  const { featureAreas, selectArea, selectFeature, selectMigration } =
    useFeatureArea();

  // Form
  const selectedForm = computed(() => {
    if (selectedFeature.value === null || selectedArea.value === null) {
      return [];
    }

    return store.getters.form(
      "app." + selectedFeature.value + "." + selectedArea.value
    );
  });

  const showForm = computed(
    () =>
      selectedArea.value !== null &&
      featureAreas.value.includes(selectedArea.value) &&
      selectedFeature.value !== null &&
      selectedIntegration.value !== null
  );

  const variableSet = ref({
    name: "scmaXentralFields",
    variables: productSelectionVariables
  });

  onMounted(async () => {
    if (store.getters?.isDev && !store.getters?.selectedApp) {
      await router.push({ name: "salesChannelManagementApps" });

      return;
    }

    // Prevent unnecessary requests
    if (isAppInitialized && integrations.value.length > 0) return;
    addEventToLoadingQueue({ key: "mountApp", group: "mountApp" });

    await store.dispatch("variables/" + LOAD_CONFIG_VALUES);
    await loadProjects();
    await getIntegrationsWithPresets();
    await loadChannelsForShop();

    toggleStatus(false);
    setXentralCustomVariables();

    removeEventFromLoadingQueue({ key: "mountApp", group: "mountApp" });
  });

  watch(
    () => isBusy.value,
    function (val) {
      if (val || initialized.value) {
        return;
      }

      initialized.value = true;
    }
  );

  function setXentralCustomVariables() {
    store.dispatch("variables/" + SET_VARIABLES, variableSet.value);
  }

  /**
   * select a specific integration
   * @param integration
   * @param target
   * @param feature
   * @param area
   * @param disableRouter
   */
  async function selectIntegration(
    integration,
    target,
    feature = undefined,
    area = undefined,
    disableRouter = false
  ) {
    if (!integration || !target) return;

    await store.dispatch("route/" + RESET_ROUTE_PARAMS);

    const isDifferentIntegration =
      integration.id !== selectedIntegration.value.id ||
      store.getters.selectedProject.id !== integration.id;

    if (isDifferentIntegration) {
      toggleStatus(false);

      DataOptions.clearCache(); // Clear the cache so that we don't use options from other integrations

      if (selectedIntegration.value?.id) {
        await store.dispatch("resetSelectedIntegrationFeatures");
      }
    }

    let selectIntegrationRequests = [];

    if (!disableRouter) {
      await router.push({
        name: "salesChannelManagementAppDetail",
        params: { area: target }
      });
    }

    // Set the integration as the current project
    // Only load forms if another integration was selected or forms is empty
    const formObj = store.getters.form();
    if (Object.keys(formObj || {}).length === 0 || !isAppInitialized.value) {
      await Promise.all([
        resetValuesToSave(),
        store.dispatch(
          UPDATE_SELECTED_PROJECT,
          store.getters.integrationProject(integration.id)
        )
      ]);
      await loadForms();
      await loadSelectedIntegration(integration);
    }

    selectedFeature.value = null;

    feature = feature || features.value[0];
    if (!feature) {
      return;
    }
    selectFeature(feature);

    if (feature === "migration") {
      selectIntegrationRequests.push(
        getWorkflowPendingStatus("export").then(isPending => {
          migrationStepFinished.value.export = isPending ?? false;
        })
      );
      selectIntegrationRequests.push(
        getWorkflowPendingStatus("import").then(isPending => {
          migrationStepFinished.value.import = isPending ?? false;
        })
      );
    }

    // select first area
    area = area || featureAreas.value[0];
    if (area) {
      selectArea(area);
    }

    selectIntegrationRequests.push(checkDefaultValues());

    await Promise.all(selectIntegrationRequests);

    toggleStatus(true);
  }

  // Check if preset values are missing while loading an integration
  async function checkDefaultValues() {
    const { data } = await Presets.getAll({ noPagination: true });

    if (Object.keys(data || {}).length <= 0) return;

    const presets = data.map(item => item.name);
    const integration = store.getters.selectedIntegration;

    for (const feature of features?.value || []) {
      for (const area of Object.keys(integration?.[feature] || {})) {
        if (presets.includes(feature + _.upperFirst(area))) continue;

        const payload = {
          feature: feature,
          name: area,
          value: integration[feature][area].value,
          area: area,
          features: features.value
        };

        await setValuesToSave(payload);
      }
    }
  }

  return {
    assignmentTables,
    initialized,
    isBusy,
    isBusyMount,
    selectedForm,
    showForm,
    selectIntegration,
    selectMigration
  };
};
